// var stickyHeader = false;
var transformHomepageDonationAmountImages = true;
// var flyoutNav = true;
// var fullscreenNav = true;
// var homeQuickGivingPreFooter = false;
// var countUpStats = false;
// var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
var feedItemsCarouselBreakpoint = 768; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
var feedItemsCarouselAutoplay = true;
var feedItemsCarouselAutoplaySpeed = 4000;

$('.homeFeatures').insertAfter('.homeFeedBox1');
$('.homeFeatures').wrap('<div class="homeFeatures2Wrapper">');

$('<a class="cta-button">Read more</a>').css('margin-top', '10px').insertAfter('.homeBox1 .homeFeatureDetailsWrapper p');